/* General card styles */
.card {
  background:linear-gradient(-135deg, rgba(234, 236, 239, 0.732), rgba(176, 179, 183, 0.693));
  outline: none;
  border:none;
  color: black;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
  width:300px;
  /* Ensure content breaks to a new line if necessary */
  word-wrap: break-word;
  overflow-wrap: break-word;
  height: 350px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  min-height: 300px; /* Set a minimum height */
  margin-right: 15px; /* Add spacing between cards */
}

/* Title inside each card */
.card h3 {
  font-size: 1.5rem;
  color:#2a2a2a;
  margin-bottom: 10px;
}

.card p {
  margin-bottom: 15px;
  font-size: 1rem;
  width: 100%;
  line-height: 1.5;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto; /* Optional for hyphenation */
}

/* Features section inside the card */
.features {
  display: flex;
  flex-wrap: wrap; /* Ensure features wrap to new lines */
  gap: 10px;
}

.text-ind{
  color: grey;
  font-size: 1.2rem;
  width: 65%;
  margin-bottom: 70px;
  padding-right: 15px;
}

/* Feature tag inside each card */
.feature-tag {
  background-color: #2d2d2d8b;
  border-radius: 20px;
  padding: 8px 12px;
  margin:5px;
  font-size: 0.85rem;
  color: #ebebeb;
  white-space: normal; /* Ensure feature tags break to the next line if too long */
}

.card:hover {
  box-shadow: 0px 0px 0px 2px #4242428b; /* Soft box-shadow on hover */
}


/* Scrolling container that holds the cards */
.scrolling-container-idws {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: auto; /* Remove the 100vh, cards should size based on content */
  background-color: #f2f6f7;
  /* padding: 20px 0 100px 0; Add some padding to top/bottom */
  /* margin-bottom: 200px; */
}

.scrolling-container-idws div {
  display: inline-block; /* Ensure cards are inline for horizontal scrolling */
}

/* Section title */
.idws-head {
  font-size: 3.5rem;
  font-weight: 700;
  text-align: end;
  padding: 130px 35px 30px 0;
  color: rgb(63, 63, 63);
}

@media screen and (max-width: 768px) {
 /* Section title */
.idws-head {
  font-size: 36px;
  font-weight: 700;
  line-height: 2.3rem;
  text-align: start;
  color: rgb(63, 63, 63);
  padding-left:  25px;
}
.text-ind{
  font-size: 1.2rem;
  width: 100%;
  margin: auto;
  text-align: left;
  margin-bottom: 40px;
  padding-right:0px;

}

  
}

.idws-main-cont{
  padding-bottom: 90px;
  background-color: #f2f6f7;
}
