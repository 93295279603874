/* Carousel container */
.carousel {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Inner container for items */
  .carousel-inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Each carousel item */
  .carousel-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    /* opacity: 0; */
    transition: opacity 0.5s ease-in-out;
    z-index: 0;
  }
  
  /* Content of the carousel (image/video and text) */
  .carousel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .carousel-content img, .carousel-content video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  
  /* Text content */
  .carousel-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 20px;
    margin-left: 50px;
    z-index: 10;
    color: white;
  }
  
  .carousel-text h1, .carousel-text h2 {
    color: white;
  }
  
  .carousel-text h1 {
    font-size: 5rem;
    font-weight: 700;
    width:70%;
    line-height: 1;
    left: 0;
  }
  
  .carousel-text h2 {
    font-size: 2rem;
    font-weight: 300;
  }
  
  /* Animations for sliding */
  
  /* Slide in from the right */
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Slide out to the left */
  @keyframes slideOutLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  /* Slide in from the left */
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Slide out to the right */
  @keyframes slideOutRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
  
  /* Apply the animation when the slide is active */
  .slide-in-right {
    animation: slideInRight 0.5s forwards;
    z-index: 1;
  }
  
  .slide-out-left {
    animation: slideOutLeft 0.5s forwards;
    z-index: 1;
  }
  
  .slide-in-left {
    animation: slideInLeft 0.5s forwards;
    z-index: 1;
  }
  
  .slide-out-right {
    animation: slideOutRight 0.5s forwards;
    z-index: 1;
  }
  
  /* Left and right arrows */
  .left-arrow, .right-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: white;
    padding: 20px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .left-arrow {
    left: 15px;
  }
  
  .right-arrow {
    right: 25px;
  }
  
  .arrow.disabled {
    opacity: 0.5;
  }
  
  
  .carousel-blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
  }


  @media (max-width: 991px) {
    .carousel-text {
      margin: 0px 40px;
    }
  
    .carousel-text h1 {
      font-size: 3rem;
      width: 100%;
    }
  
    .carousel-text h2 {
      font-size: 1.5rem;
    }
   
    .left-arrow{
      display: none;
    }

  }

  .floating-contact-button {
    cursor: pointer;
    background-color: var(--main);
    color: white;
    font-weight: bold;
    transition: top 0.5s, left 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
  }
  
  .floating-contact-button.following {
    transition: none; 
  }
  
  .boundary:hover{
    .floating-contact-button {
      transform: scale(0.7);
      color: var(--main);
      background-color: white;
    }
  }


  .boundary{
    position: absolute;
    z-index: 1000;
    width: 27%;
    height: 40%; 
    right: 5%;
    bottom: 5%; 
  }

  .floating-contact-button{
    position: absolute;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    transition:  top 0.1s, left 0.1s;
  }


  @media screen and (max-width: 768px){
    .boundary{
      position: absolute;
      z-index: 1000;
      width: 10%;
      height: 20%; 
      right: 35%;
      bottom: 8%; 
    }
  
    .right-arrow, .left-arrow{
      font-size:25px;
    }
    .right-arrow{
      right: 5px
    }
    .left-arrow{
      left: 5px;
    }
    
  .floating-contact-button{
    position: absolute;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    transition:  top 0.1s, left 0.1s;
  }
  }