.blue-body{
    padding-top: 100px!important;
    padding-bottom: 100px!important;
    background-color: #b5c1d036;
}

.SolutionsCard{
    background-color: white;
    border-radius: 20px;
    transition: all .5s;
    padding: 30px 40px;
    cursor: pointer;
    min-height: 250px;
}


.SolutionsCard {
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out box-shadow 1s ease-in-out;
}

.SolutionsCard.visible {
  opacity: 1;
}

.SolutionsCard h3{
    font-size: 2.2vw;
    font-weight: 700;
    margin-bottom: 20px;
    color: var(--main);
}

.SolutionsCard p{
  font-size: 20px;
}

.SolutionsCard:hover{
    box-shadow: 0px 0px 10px #8c9eaf62;
}

.service-tag-top{
    width: 75%;
    font-size: 2vw;
    padding:10px;
  }
  .top-sec{
    margin: 140px 0px;
}
.top-sec-head{
  font-size: 5vw;
  font-weight: 700;
  text-align: right;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;

}
.top-sec-head div{
  width: 70%;
  padding: 10px;
  line-height: 1em;
}

.padding-5{
  margin: 140px 0px;
}

@media (max-width:990px) {
  .top-sec-head{
    font-size: 35px;
    text-align: start;
    width: 80%;
  }

  .padding-5{
    margin: 0px 20px 20px 20px;
    width: 90%;
  }

  .top-sec{
    margin: 100px 20px;
  }
  .top-sec-head div{
    width: 100%;
    margin-bottom: 20px;
  }
  .service-tag-top{
    font-size: 20px;
    margin:0;
    width: 100%;
  }
}


.sticky-column {
    position: sticky;
    top: 20%;
    margin-bottom: 100px;
    padding: 10px;
    z-index: 1;
    text-align: left;
    font-weight: 700;
    line-height: 1em;
    height: 200px;
    font-size: 3.5vw;
  }
  .sticky-column p {
    background: linear-gradient(125deg, var(--main), #000); /* Your gradient colors */
    -webkit-background-clip: text; /* For WebKit-based browsers like Chrome and Safari */
    background-clip: text; /* Standard */
    color: transparent; /* Make the text color transparent to show the gradient */
  }
  .techweuse-head{
    padding-left: 80px;
  }
  .techweuse-head h1{
    font-size: 50px;
    font-weight: 700;
  }

  .techweuse-services{
    background-color: white;
    margin: 150px 0 7 0px 0;
    padding: 60px 0;
  }

  .spec-head{
    color: var(--main);
    font-size: 30px;
    font-weight: bold;
  }
  .techweuse-col{
    padding: 20px 70px 50px 70px;
  }

  .spec-pad{
    padding: 40px 60px;
    margin: 0;
  }

  .dev-p-new{
    width: 60%;
    margin: 0 80px;
    font-size: large;
  }

  @media (max-width: 768px) {
    .spec-pad{
        padding: 10px;
    }
    .dev-p-new{
      width: 90%;
      margin: 0 22px;
    }
    .techweuse-head{
      padding: 0 37px;
    }
    .techweuse-head h1{
      font-size: 36px;
      line-height: 1em;
      width: 70%;
      font-weight: 700;
    }
  
    .SolutionsCard{
      padding: 22px 25px;
    }
    .SolutionsCard h3{
      font-size: 30px;
      font-weight: 700;
  }
  
  .SolutionsCard p{
    font-size: 17px;
  }
    
  .techweuse-services{
    background-color: white;
    margin: 80px 0 50px 0;
    padding: 60px 0;
  }
  
  }