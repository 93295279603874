.App{
  font-size: 20px;
  font-weight:normal;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.head-highlight{
  color: var(--main);
}

.blog-lines{
  width:100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 768px) {
  .bloglines-img-cont {
    overflow-x: hidden;
  }
  .blog-lines{
    width: 200%;
    height: auto;
    object-fit: cover;
  }
}

/* .page-container {
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.blog-section {
  flex-grow: 1;
  background-color: white;
  overflow-y: auto;
}

.footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  padding: 20px;
  z-index: -1;
}

.blog-section::-webkit-scrollbar {
  width: 10px;
}
 */


 .loading-home{
  z-index: 11000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: disappear 2s ease-in-out 2s forwards;
 }

  @keyframes disappear {
    0% {
      opacity: 1;
      display: block;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }

  .send-up-btn {
    width: 40px;
    height: 40px;
    background-color: #a8babe53;
    border-radius: 100%;
    position: fixed;  /* Fixes the position relative to the viewport */
    bottom: 40px;     /* 30px from the bottom */
    left: 50%;        /* Centers horizontally */
    transform: translateX(-50%); /* Adjusts for exact centering */
    z-index: 1000;    /* Ensures the button is on top of other elements */
    display: none;
    cursor:pointer;
    
  }
  
  .send-up-btn.visible{
    display: flex;
  align-items:center;
  justify-content: center;
}
