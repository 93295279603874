.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1003;
    padding:0;
    padding-top: 5px;
    transition: all .5s ease-in-out;
    transition: background-color 0s, top 0.4s;
  }
  
  .navbar-transparent {
    background-color: transparent;
    color: white;
  }
  
  .navbar-solid {
    background-color: #fff;
    border-bottom: 1px solid #c9cbce;
  }
  
  .navbar-hidden {
    top: -80px; 
  }

  .navbar-transparent .nav-link {
    font-size: 18px;
    margin-right: 20px;
    transition: color 0.2s ease-in-out;
    color: white;
    cursor: pointer;
    transition: all .5s;
  }
  
  /* Other existing styles */
  .navbar-solid .nav-link {
    font-size: 18px;
    cursor: pointer;
    margin-right: 20px;
    color: #494949fa;
    /* margin-top:15px; */
    transition: color 0.2s ease-in-out;
    transition: all .5s;
  }
  
  .navbar .nav-link:hover {
    color: var(--main);
  }
  
  .navbar .nav-link.active {
    color: var(--main);
    font-weight: 600;
  }

  
  .navbar .navbar-toggler {
    border: none !important;
    outline: none !important;
  }

  /* border shouldnt be there on clicking too */
    .navbar .navbar-toggler:focus {
        outline: none !important;
        border: none !important;
    }
  
  .navbar .toggle-btn {
    cursor: pointer;
    position: relative;
    z-index: 1100;
  }
  
  .navbar .toggle-btn .bar {
    width: 25px; /* Adjust width if needed */
    height: 3px;
    margin: 7px 0;
    background-color: #0b0b0b;
    transition: all .2s ease-in-out;
  }
  .navbar-transparent .navbar-toggler .toggle-btn .bar{
    background-color: white;
  }
  /* Ensure checkbox is not visible and has no border */
  #toggle-menu {
    display: none; /* Hide the checkbox */
  }
  
  #toggle-menu:focus {
    outline: none; /* Remove outline */
  }
  
  /* Show the menu when toggled */
  .collapse.navbar-collapse.show {
    display: flex !important; /* Show when toggled */
  }
  
  /* Mobile specific styles */
  @media (max-width: 991px) {
    .collapse.navbar-collapse {
      display: none; /* Hidden by default */
      flex-direction: column; /* Stack items vertically */
    }
    .nav-link {
      margin: 0;
      text-align :right;
    }
    .navbar-solid .nav-link, .navbar-transparent .nav-link {
      text-align :right;
      margin-right: -10px;
      padding: 3px 0;

    }
  }
  
  .navlogo{
    width: auto;
    height: 50px;
  }







  .expanded-navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 20px 0;
    opacity: 1;
    /* visibility: visible; */
    transition: all 4s ease-in-out;
  }
  
  .expanded-navbar.hidden {
    opacity: 0;
    top: -100%;
    /* visibility: hidden; */
    transition: all 0.4s ease-in-out;
  }
  
  .expanded-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .extended-column {
    flex: 3;
    padding: 0 15px;
  }

  .extended-column-1 {
    flex: 2;
    padding: 0 15px;
  }
  
  .learn-more-btn {
    background-color: white;
    color: var(--main);
    border: none;
    cursor: pointer;
  }
  .learn-more-btn:hover {
    color: #016a74;
  }
  .right-arrow-ex{
    color: var(--main);
  }
.pad-s{
  padding-left: 5vw;
}
  .extended-column ul li{
    list-style-type: none;
  }
  
  .extended-column ul li a{
    text-decoration: none;
    color: black;
  }

  
  .extended-column ul li a:hover{
    color: var(--main);
  }


  @media (max-width: 768px){
    .extended-column{
      padding: 0;
    }
      .extended-column h3{
      font-size:5vw;
    }
    .extended-column p{
      font-size:3vw;
    }
    .pad-s{
      padding: 0;
    }
    .extended-column ul li{
      font-size: 4vw;
      list-style:disc;
    }
    .right-arrow-ex{
      display: none;
    }
    .learn-more-btn{
      padding: 0;
      margin: 0;
      width: 100%;
      text-align: start;
    }
    
  }