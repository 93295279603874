/* General card styles */
.card-1 {
  background:linear-gradient(-135deg, black, rgb(45, 45, 45));
  outline: none;
  border:none;
  color: white;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
  width:300px;
  /* Ensure content breaks to a new line if necessary */
  word-wrap: break-word;
  overflow-wrap: break-word;
  height: 350px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  min-height: 300px; /* Set a minimum height */
  margin-right: 15px; /* Add spacing between cards */
}

/* Title inside each card */
.card-1 h3 {
  font-size: 1.5rem;
  color:var(--main);
  margin-bottom: 10px;
}

.card-1 p {
  margin-bottom: 15px;
  font-size: 1rem;
  width: 100%;
  line-height: 1.5;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto; /* Optional for hyphenation */
}

/* Features section inside the card */
.features-1 {
  display: flex;
  flex-wrap: wrap; /* Ensure features wrap to new lines */
  gap: 10px;
}

.text-ind-1{
  color: grey;
  font-size: 1.2rem;
  width: 65%;
  margin-bottom: 70px;
  padding-right: 15px;
}

/* Feature tag inside each card */
.feature-tag-1 {
  background-color: #5757578b;
  border-radius: 20px;
  padding: 8px 12px;
  margin:5px;
  font-size: 0.85rem;
  color: #bfbfbf;
  white-space: normal; /* Ensure feature tags break to the next line if too long */
}

.card-1:hover {
  box-shadow: 0px 0px 0px 2px var(--main); /* Soft box-shadow on hover */
}


/* Scrolling container that holds the cards */
.scrolling-container-idws-1 {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: auto; /* Remove the 100vh, cards should size based on content */
  background-color: #000000;
  /* padding: 20px 0 100px 0; Add some padding to top/bottom */
  /* margin-bottom: 200px; */
}

.scrolling-container-idws-1 div {
  display: inline-block; /* Ensure cards are inline for horizontal scrolling */
}

/* Section title */
.idws-head-1 {
  font-size: 4rem;
  font-weight: 700;
  text-align: end;
  padding: 130px 35px 30px 0;
  color: white;
}

@media screen and (max-width: 768px) {
 /* Section title */
.idws-head-1 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.3rem;
  text-align: start;
  color: white;
  padding-left:  25px;
}
.text-ind-1{
  font-size: 1.2rem;
  width: 100%;
  margin: auto;
  text-align: left;
  margin-bottom: 40px;
  padding-right:0px;

}

  
}

.idws-main-cont-1{
  padding-bottom: 90px;
  background-color: black;
}
