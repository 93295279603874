.blog-det-img-cont {
    height: 80vh;
    width: 100%; /* Full width of the screen */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px; /* Adjust this padding as needed */
    margin-top: 100px;
}

.blog-det-img {
    max-width: 100%; /* Image will fill the container width, respecting the padding */
    max-height: 100%; /* Image will fit within the container height */
    object-fit: contain; /* Maintain aspect ratio and fit within the container */
    border-radius: 30px;
}
 
  .main-section-blog{
    padding: 0px 180px;
  }

  
  .blog-det-title{
    color: var(--main);
  }

  .blog-det-subhead{
    color: grey;
    font-size: 1rem ;
  }

  .blog-det-description{
    font-size: 1.4rem;
    text-align: justify;
  }
    
      @media (max-width:768px) {
        .main-section-blog{
          padding: 0 30px;
        }
        .blog-det-img-cont {
          height: 30vh;
          width: 100%; /* Full width of the screen */
          display: flex;
          justify-content: center;
          margin-top: 80px;
          padding: 0;
      }
      .blog-det-description{
        font-size: medium;
      }
      }