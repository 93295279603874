.footer{
    background-color: black;
    color: rgb(155, 155, 155);
    padding: 30px;
}
.footer.row{
    margin:0!important;
}
.footer-head{
    color: white;
    font-size: 1.7rem;
}

.dot-footer{
    width:10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--main);
}

.list-item{
    font-size: 1rem;
    color: rgb(155, 155, 155)
}

.footer-logo{
    width:200px;
    height: auto;
    margin-left:5px;
}

.footer-bottom{
    margin: 80px 30px 10px 30px; 
    padding-top: 80px;
    text-align: center;
    border-top: 1px solid #53535396;
}


.footer-bottom .col-md-4{
    justify-content: center;
}

.list-item-small{
    font-size: .8rem;
}

.footer-icon-circle{
    padding:5px;
    width: 45px;
    height: 45px;
    margin: 5px 0;
    display: inline-block;
    border-radius: 100px;
    background-color: transparent;
    cursor: pointer;
}

.footer-icon-circle svg{
    fill: white!important;
}

.footer-icon-circle:hover{
    svg{
        fill: var(--main)!important;
    }
}

.hoverClick, .list-item, .list-item-large, .list-item-small{
    transition: all .2s ease-in-out;
    cursor: pointer;
}
.hoverClick:hover, .list-item:hover, .list-item-large:hover, .list-item-small:hover{
    color: var(--main);
}

.list-item-large{
    font-size: 1.2rem;
    color: white;
}

.foot-hidden{
    display:none;
}

@media (max-width: 768px){
    .footer{
        padding: 5px;
    }
    .footer-bottom{
        padding: 30px 0;
        margin: 30px 10px;
    }
    .contact-footer-text{
        font-size: small;
        color: white;
    }
    .footer-logo{
        width:130px;
        height: auto;
        margin-left:5px;
        transform: translateY(-10px);

    }
    .footer-icon-circle{
        margin:0;
        padding: 0;
    }
    .contact-foot{
        padding: 10px 5px;
    }
    .col-4{
        width: 50%;
        padding: 0;
    }
    .col-4 .footer-head{
        font-size: large;
    }
    .col-4 .list-item{
        font-size: small;
    }
    .contact-col{
        display:none;
    }
    .foot-hidden{
        display:block;
        font-size: small;
    }
}