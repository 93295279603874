.blog-card-12{
  cursor:pointer;
}

.image-cont-12 {
  overflow: hidden;
  border-radius: 30px;
  height: 20vw;
  background-color: rgb(255, 255, 255);
}

.image-cont-12 img {
  border-radius: 30px;
  transition: all 0.4s ease-in-out;
}

.blog-card-12:hover{
  .image-cont-12 img {
    transform: scale(1.1); /* Only the image will zoom */
  }
  .head-12{
    color: var(--main);
  }
}


.head-12{
  font-size: 1.5vw;
  font-weight: 700;
  padding: 10px 20px;
  padding-bottom: 0;
  transition: all 0.3s ease-in-out;
}

.filler-12{
  height: 20vw;
  width: 100%;
  background-color: rgb(234, 234, 234);
  animation: filler 1s infinite alternate ease-in-out;
}

@keyframes filler {
  from{
    background-color: rgb(234, 234, 234);
  }
  to{
    background-color: rgb(202, 203, 203)
  }
}

.blog-date-12{
  padding: 0 20px;
  font-size: small;
}

.translate-12{
  transform: translateY(10vw);
}

.button-12{
  margin-top:200px
}

.filler-date-12{
  background-color: rgb(234,234,234);
  color: rgb(234,234,234);
  height: 20px;
  width: 10%;
}

.filler-head-12{
  background-color: rgb(234,234,234);
  color: rgb(234,234,234);
  height: 20px;
  width: 30%;
  margin:10px;
  border-radius: 10px;
  animation: filler 1s infinite alternate ease-in-out;
}

@media (max-width:768px) {
  .image-cont-12, .image-cont-12 img{
    border-radius: 20px;
    height: 48vw;
  }
  .head-12{
    font-size: 4.5vw;
  }
  .button-12{
    margin-top:70px
  }
  .filler-12{
    height: 48vw;
  }
  .translate-12{
    transform: translateY(0);
  }
}