.policy p, li{
    text-align: justify;
    font-size: 1.2rem;
}

.policy a{
    color: var(--main);
}

.policy{
    margin: 0;
    margin-top:150px;
}