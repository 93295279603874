.blog-form-cont{
    box-shadow: 0 0 10px 0 #ddd;
}

.blog-post-label{
    font-size: small;
    margin-left: 10px;
    color: var(--main);
}
.blog-post-heading{
    font-size: 4rem;
    font-weight: bold;
    margin: 10px 0;
}
.blog-form-container {
    position: relative;
    background-image: url('../../public/blog-background.png');
    background-size: cover; 
    background-position: center;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.blog-form-cont {
    background: rgba(255, 255, 255, 0.461); 
    padding: 2rem;
    border-radius: 25px; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); 
    backdrop-filter: blur(20px); 
}
.react-quill-cont{
    height: 220px;
}

.react-quill{
    height: 150px;
}

@media (max-width:768px) {
    .blog-form-cont{
        margin: 20px auto;
        padding: 10px 30px!important;
    }
    .head-blog-fill{
        margin-top: 40px;
    }
    .react-quill-cont{
        height: 320px;
    }
    .react-quill{
        height: 200px;
    }

}

.blogpost-btn-cont .hover-button{
    height: 40px!important;
}




/* General styling for the modal */
.delete-confirmation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Dark transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure it is above everything */
    backdrop-filter: blur(5px); /* Apply blur to the background */
}

/* Styling for the confirmation box itself */
.confirmation-box {
    background-color: #fff; /* White box */
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
    z-index: 10000; /* Ensure the box is above the blurred background */
    animation: fadeIn 0.3s ease; /* Smooth fade-in effect */
}

/* Add some spacing between the buttons */
.confirmation-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

/* Fade-in animation for the modal */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
