
.txt-lg-contact{
    font-size: 5vw;
    width:100%;
    font-weight: 600;
  }

  .padding-contact{
    padding-left: 10vw;
  }

  .contac-margin{
    padding:50px 0;
    background-color: white;
  }
  .txt-sm-contact{
    font-size: 3vw;
    width:100%;
  }


  .custom-dark-button {
    position: relative;
    overflow: hidden; /* Prevents text overflow during animation */
    display: inline-block;
    border: none;
    cursor: pointer;
    outline: none;
    margin: 8px 5px;
    height: 43px;
    font-size: 1.1vw;
    width: 14vw;
    border-radius: 100px;
    display: flex;
    justify-content: center; /* Center text horizontally */
    align-items: center;    /* Center text vertically */
    text-align: center;     /* Ensure text alignment is center */
    background-color: rgb(26, 26, 26);
    color: #ffffff;
    transition: all 0.3s ease-in-out;
}

.custom-dark-button:hover {
    box-shadow: 0px 5px 10px var(--main);
    }


    @media (max-width:768px) {
        .custom-dark-button{
            width: 35vw;
            font-size: 3vw;
        }
        .txt-lg-contact{
            font-size: 10vw;
            width:100%;
          }
          .txt-sm-contact{
            font-size: 8vw;
            width:100%;
          }
        .contac-margin{
            padding:100px 0;
        }
    }